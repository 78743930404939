import React from 'react';
function Roadmap() {
  const roadmapPhases = [
    { phase: 'Phase 1', details: 'Launch Jameis Coin and set up wallet integration.' },
    { phase: 'Phase 2', details: 'Establish liquidity pool and presale for early adopters.' },
    { phase: 'Phase 3', details: 'Marketing campaigns and partnerships with crypto influencers.' },
    { phase: 'Phase 4', details: 'Launch on major exchanges.' },
    { phase: 'Phase 5', details: 'We buy a SpaceX ticket for Jameis and launch him into space.' },
  ];
  return (
    <section id="roadmap">
      <h2>Jameis Coin Roadmap</h2>
      <ul>
        {roadmapPhases.map((item, index) => (
          <li key={index}>
            <strong>{item.phase}:</strong> {item.details}
          </li>
        ))}
      </ul>
    </section>
  );
}
export default Roadmap;