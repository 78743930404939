import React from 'react';
function Phantom() {
  return (
    <section id="phantom">
      <h2>How To Buy</h2>
      <ol>
        <li>
          Create a Solana wallet using{" "}
          <a
            href="https://phantom.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            Phantom Wallet
          </a>
          .
        </li>
        <li>Load your wallet with SOL. You can purchase SOL directly in Phantom or you can send SOL from another exchange.</li>
        <li>Swap the SOL for Jameis Coin on <a href="https://pump.fun/coin/GcE1vRKjj4MK1A3Dnmo5PB12cM8AVc4nTSZ6Hem4pump" target="_blank" rel="noopener noreferrer">Pump.Fun</a></li>
      </ol>
    </section>
  );
}
export default Phantom;