import React from 'react';
import ContactAddress from '../components/ContactAddress';
import SpaceCrab from '../components/SpaceCrab';
import Tokenomics from '../components/Tokenomics';
import Roadmap from '../components/Roadmap';
import Timeline from '../components/Timeline';
import Phantom from '../components/Phantom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Charity from '../components/Charity';
import '../styles/Home.css'

const Home = () => {
    return (
        <div className="home-container">
            <Header />
            <Phantom/>
            <ContactAddress/>
            <SpaceCrab />
            <Tokenomics />
            <Roadmap />
            <Timeline />
            <Charity />
            <Footer />
        </div>
    );
};

export default Home;
