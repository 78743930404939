import React from 'react';
import dreams_forever from '../images/dreams_forever.png';
import '../styles/Charity.css'

const Charity = () => {

  return (
    <div id="charity">
      <h2>Dreams Forever</h2>
      <p> For every $100,000 in market cap that we gain we will donate $100 to the Jameis Winston Dreams Forever Foundation.</p>
      <img
          className="charity-image"
          src={dreams_forever}
          alt='load fail'
        />
    </div>
  );
};

export default Charity;
