import React from 'react';
function Tokenomics() {
  return (
    <section id="tokenomics">
      <h2>Jameis Coin Tokenomics</h2>
      <ul>
        <li><strong>Total Supply:</strong> 1 Billion Coins</li>
        <li> Pumpfun fair launch </li>
        <li> Liquidity Pool Locked Forever </li>
        <li> Ownership renounced </li>
      </ul>
    </section>
  );
}
export default Tokenomics;