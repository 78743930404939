import React, { useState } from 'react';
import crab_legs from '../images/crab_legs.png';
import crutches_dance from '../images/crutches_dance.png';
import eat_a_w from '../images/eat_a_w.gif';
import fumble from '../images/fumble.gif';
import jameis_pain from '../images/jameis_pain.png';
import out_of_shape_jameis from '../images/out_of_shape_jameis.png';
import what_did_he_say from '../images/what_did_he_say.png';
import '../styles/Timeline.css';

const Timeline = () => {
  const events = [
    {
      date: "May, 2014",
      description: "Having won the national championship earlier in 2014, Jameis Winston gets cited for stealing crab legs from Publix that same May.",
      image: crab_legs,
    },
    {
      date: "January 1st, 2015",
      description: 'Jameis Winston fumbles the ball on 4th down in the 2015 Rose Bowl, in what the internet would dub as the "Winston Waltz"',
      image: fumble,
    },
    {
      date: "February, 2015",
      description: "In the lead up to the 2015 NFL draft, the photo of Jameis looking out of shape takes the internet by storm.",
      image: out_of_shape_jameis,
    },
    {
      date: "November 5th 2017",
      description: "Jameis Winston famously “eats a W” in a fiery pregame speech before beating the New Orleans Saints in 2017.",
      image: eat_a_w,
    },
    {
      date: "September 12th, 2021",
      description: 'Following a Week 1 matchup with the Green Bay packers, Jameis recalls the words his trainer told him pregame.',
      image: what_did_he_say,
    },
    {
      date: "October 31st, 2021",
      description: "After suffering a torn ACL and MCL during a win over the Buccaneers, Jameis celebrates by dancing with his crutches.",
      image: crutches_dance,
    },
    {
      date: "September 11th, 2022",
      description: 'Jameis is asked where he felt pain. He responded: "Yeah just pain. There was pain everywhere."',
      image: jameis_pain,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % events.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? events.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="timeline-slideshow-container">
      <h1>Best Jameis Moments</h1>
      <div className="timeline-slide">
        <h3>{events[currentIndex].date}</h3>
        <img
          className="timeline-image"
          src={events[currentIndex].image}
          alt={events[currentIndex].date}
        />
        <p>{events[currentIndex].description}</p>
      </div>
      <div className="timeline-controls">
        <button onClick={prevSlide}>&lt;</button>
        <button onClick={nextSlide}>&gt;</button>
      </div>
    </div>
  );
};

export default Timeline;
