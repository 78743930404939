import React from 'react';
import { FaTelegramPlane, FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import '../styles/Header.css';

const Header = () => {
    return (
      <header className="header">

        <h1>Jameis Coin</h1>
        <div className="header-content">
          <div className="header-socials">
            <a href="https://t.me/+vbICpmZ7d2pjNjYx" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaTelegramPlane />
            </a>
            <a href="https://discord.com/invite/YCMDhT7kbp" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaDiscord />
            </a>
            <a href="https://x.com/jameiscoin" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaXTwitter />
            </a>
          </div>
        </div>
      </header>
    );
  };

export default Header;
