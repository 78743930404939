import React from 'react';
import { FaTelegramPlane, FaDiscord } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import jameis from '../images/Jameis.png'; 
import '../styles/Footer.css';

const Footer = () => {
    return (
      <footer className="footer">
        <div className="footer-content">
          <img src={jameis} alt="Jameis Logo" className="footer-logo" />
          <p className="footer-text">© {new Date().getFullYear()} Jameis Coin. All Rights Reserved.</p>
          <div className="footer-socials">
            <a href="https://t.me/+vbICpmZ7d2pjNjYx" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaTelegramPlane />
            </a>
            <a href="https://discord.com/invite/YCMDhT7kbp" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaDiscord />
            </a>
            <a href="https://x.com/jameiscoin" target="_blank" rel="noopener noreferrer" className="social-link">
              <FaXTwitter />
            </a>
          </div>
        </div>
      </footer>
    );
  };

export default Footer;
