import '../styles/SpaceCrab.css';
import space_crab from '../images/space_crab.png';

const SpaceCrab = () => {

  return (
    <div>
        <img
            className="timeline-image"
            src={space_crab}
            alt='load fail' />
    </div>
  );
};

export default SpaceCrab;