import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaClipboard } from 'react-icons/fa'; 
import '../styles/ContactAddress.css';

const ContactAddress = () => {
  const staticText = "GcE1vRKjj4MK1A3Dnmo5PB12cM8AVc4nTSZ6Hem4pump"; 

  return (
    <div className="contact-address-container">
      <h3>Contract Address</h3>
      <div className="contact-address-wrapper">
        <span className="contact-address">{staticText}</span>
        <CopyToClipboard text={staticText}>
          <button className="copy-button">
            <FaClipboard /> 
          </button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default ContactAddress;
